import apiClient from '../../../axios'

export default class AccountsRotationService {
  static async updateAgentBuyRotationState(agentId, state) {
    try {
      const response = await apiClient.patch(`/admin/accounts/${agentId}/rotation/buy/${state}`)
      return response
    } catch (error) {
      console.error('Error updating agent buy rotation state:', error)
      throw error
    }
  }
}
