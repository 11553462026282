<template>
  <a-table
    :columns="requisitesColumns"
    :dataSource="requisites"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="requisitesLoading"
    @change="handleTableChange"
    class="sale-requisite-table"
  >
    <template v-slot:psystems="psystems">
      <div class="psystems" :class="{'big-size': psystems.length > 3}">
        <template v-for="psystem in psystems">
          <div class="psystem" :key="psystem.id">
            <span style="width: 18px" class="text-center mr-1"><img :src="psystem.img_path" width="18" /></span>
            <span>{{ psystem.name.length > 16 ? psystem.name.substr(0,16) + '..' : psystem.name }}</span>
          </div>
        </template>
      </div>
    </template>
    <template v-slot:address="address, record">
      {{ address }}
      <span class="d-block text-gray-5">{{ record.name }}</span>
    </template>
    <template v-slot:max_amount="max_amount, record">
          <span>
            {{ max_amount }}
          </span>
      <span>
            <a-tooltip :title="record.currency.desc" placement="bottom">
              {{ record.currency.abbr }}
            </a-tooltip>
          </span>
    </template>
    <template v-slot:comment="comment">
          <span v-if="comment" class="font-size-12">
            {{ comment.substring(0,24) }} <span v-if="comment.length > 24">...</span>
            <a-tooltip v-if="comment.length > 24" :title="`${$t('buttons.btnClick')}${$t('pre.to')}${$t('buttons.btnOpen')}`" placement="left">
              <a href="javascript:;" class="text-primary font-size-14" @click="showComment(comment)"><a-icon type="eye" /></a>
            </a-tooltip>
          </span>
    </template>
    <template v-slot:actions="text, record">
      <div class="flex">
<!--            <span class="">-->
<!--              <a-tooltip v-if="record.to_any" :title="`${$t('buttons.btnDefault')}`" placement="bottom">-->
<!--                <span class="flex">-->
<!--                  <span class="small text-primary"></span> <a-radio class="ml-1" :checked="true"></a-radio>-->
<!--                </span>-->
<!--              </a-tooltip>-->
<!--              <a-tooltip v-else :title="$t('tooltips.makeRequisitesDefaultForAnyBank')" placement="bottom">-->
<!--                <a-radio class="ml-1" :checked="false" :reqId="record.id" @click="requisiteSetDefault(record)"></a-radio>-->
<!--              </a-tooltip>-->
<!--            </span>-->
        <span>
          <a-tooltip :title="$t('buttons.btnEdit')" placement="bottom">
            <a-button icon="edit" type="link" :disabled="readonly" @click="showEditRequisiteModal(record)"/>
          </a-tooltip>
        </span>
        <span>
          <a-popconfirm
            :title="`${$t('buttons.btnDelete')} ${record.name}?`"
            @confirm="deleteRequisite(record)"
            :disabled="readonly"
          >
            <a-tooltip :title="$t('buttons.btnDelete')" placement="bottom">
              <a-button icon="delete" type="link" :class="!readonly ? 'text-danger' : 'text-grey'" :disabled="readonly"/>
            </a-tooltip>
          </a-popconfirm>
        </span>
      </div>
    </template>
    <template v-slot:status="text, record">
      <span v-if="record.is_draft" class="text-gray-5">
        <a-tooltip :title="$t('common.archive')" placement="bottom">
          <a-icon type="cloud" class="font-size-18" />
        </a-tooltip>
      </span>
      <a-switch v-else v-model="record.is_active" :reqId="record.id" @change="requisiteActiveState" :disabled="readonly" />
    </template>
  </a-table>
</template>

<script>

import AccountsRequisitesService from '../../../services/api/accounts/requisites/accounts.requisites.service'

const requisitesColumns = [
  {
    title: 'Methods',
    dataIndex: 'psystems',
    key: 'psystems',
    width: '40%',
    scopedSlots: { customRender: 'psystems' },
  },
  {
    title: 'Sender`s name',
    dataIndex: 'address',
    key: 'address',
    width: '20%',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: 'Limit',
    dataIndex: 'max_amount',
    key: 'max_amount',
    width: '12%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'max_amount' },
  },
  // {
  //   title: 'Comment',
  //   dataIndex: 'comment',
  //   key: 'comment',
  //   width: '15%',
  //   scopedSlots: { customRender: 'comment' },
  // },
  {
    title: 'Action',
    dataIndex: 'actions',
    // sorter: (a, b) => a.amount - b.amount,
    align: 'right',
    width: '12%',
    scopedSlots: { customRender: 'actions' },
  },
  // {
  //   title: 'Основной',
  //   dataIndex: 'default',
  //   // sorter: (a, b) => a.amount - b.amount,
  //   align: 'center',
  //   scopedSlots: { customRender: 'default' },
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '12%',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  props: {
    accountObj: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requisitesColumns,
      pagination: { pageSize: 7 },
      requisitesLoading: false,
      requisites: [],
    }
  },
  async mounted() {
    await this.fetchRequisitesB()
  },
  methods: {
    handleTableChange() {},
    async fetchRequisitesB(params = {}) {
      try {
        this.requisitesLoading = true
        const reqData = { results: 7, ...params }
        const response = await AccountsRequisitesService.getAccountRequisitesB(this.accountObj.id, reqData)
        const pagination = { ...this.pagination }
        pagination.total = response.meta.total
        this.requisitesLoading = false
        this.requisites = response.data
        this.pagination = pagination
      } catch (e) {
        console.log(e)
      } finally {
        this.requisitesLoading = false
      }
    },
    async requisiteActiveState(checked, event) {
      try {
        const reqId = event.target.getAttribute('reqId')
        const response = await AccountsRequisitesService.changeRequisiteActiveB(reqId, +checked)
        console.log(response)
        this.$notification.success({
          message: `${this.$t('messages.requisiteStatusUpdated')}`,
          description: response.address,
        })
      } catch (e) {
        this.$notification.error({
          message: `${this.$t('messages.errorChangingStatus')}`,
          description: e.message,
        })
      }
    },
    requisiteSetDefault() {},
    async deleteRequisite(requisite) {
      try {
        await AccountsRequisitesService.deleteRequisiteB(requisite.id)
        await this.fetchRequisitesB()
        this.$notification.success({
          message: `${this.$t('messages.requisitesDeleted')}`,
        })
      } catch (e) {
        this.$notification.error({
          message: `${this.$t('messages.errorDeletingRequisite')}`,
          description: e.message,
        })
      }
    },
    showEditRequisiteModal(requisite) {
      this.$emit('openSaleRequisitePopup', requisite)
    },
    showComment() {},
  },
}
</script>

<style lang="scss">
.sale-requisite-table {
  .psystems {
    &.big-size {
      display: flex;
      flex-wrap: wrap;
      gap: 2px;
      .psystem {
        display: flex;
        flex: 0 1 33%;
        font-size: 12px;
      }
    }
    .psystem {
      & + .psystem {
        //margin-left: 8px;
      }
    }
  }
}
</style>
