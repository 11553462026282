<template>
<a-row :gutter="[32, 16]">
  <a-col :span="12">
    <div class="d-flex flex-column flex-grow-1 mb-3">
      <span class="font-weight-bold mb-1">Agents</span>
      <custom-multiselect
        v-model="selectedAgentIds"
        :options="agents"
        placeholder="Select agents"
        active-label="Agents"
      />
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <span class="font-weight-bold mb-1">Projects</span>
      <custom-multiselect
        v-model="selectedProjectIds"
        :options="projectList"
        placeholder="Select projects"
        active-label="Projects"
      />
    </div>
  </a-col>
  <a-col :span="12">
    <div class="d-flex flex-column flex-grow-1 mb-3">
      <span class="font-weight-bold mb-1">Period</span>
      <a-range-picker
        v-model="datetime"
        format="DD-MM-YYYY"
        :allowClear="false"
        :disabled-date="disabledDateHandler"
      />
    </div>
  </a-col>
  <a-col :span="24">
    <div class="d-flex flex-grow-1 justify-content-end">
      <a-button type="primary" @click="exportCSVHandler" :loading="isExportCSVLoading">Export</a-button>
    </div>
  </a-col>
</a-row>
</template>

<script>
import CustomMultiselect from '../../components/multiselect/CustomMultiselect.vue'
import { mapGetters } from 'vuex'
import AccountsService from '../../services/api/accounts/accounts.service'
import { downloadFileByLink } from '../../utils'

export default {
  name: 'exportRewardsCSVModal',
  components: { CustomMultiselect },
  props: {
    typeAgents: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      agents: [],
      selectedAgentIds: [],
      selectedProjectIds: [],
      isExportCSVLoading: false,
    }
  },
  computed: {
    ...mapGetters(['agentList', 'projectList']),
    selectedAgents() {
      return [...this.agents].filter(agent => this.selectedAgentIds.includes(agent.id))
    },
    maxPeriodDate() {
      if (this.typeAgents === 'archived' && this.selectedAgents.length) {
        const maxDate = this.selectedAgents
          .map(agent => agent.drafted_at)
          .filter(date => date)
          .sort((a, b) => new Date(b) - new Date(a))[0]
        return maxDate ? this.$moment(maxDate) : null
      }
      return null
    },
    minPeriodDate() {
      return this.maxPeriodDate ? this.$moment(this.maxPeriodDate).subtract(1, 'year') : null
    },
  },
  watch: {
    selectedAgentIds() {
      if (this.typeAgents === 'archived') {
        const today = this.$moment().startOf('day')
        if (this.maxPeriodDate && today.isBetween(this.minPeriodDate, this.maxPeriodDate, null, '[]')) {
          this.datetime = [today, this.maxPeriodDate]
        } else {
          this.datetime = [this.minPeriodDate, this.maxPeriodDate]
        }
      }
    },
  },
  methods: {
    async exportCSVHandler() {
      try {
        this.isExportCSVLoading = true
        const payload = this.prepareExportPayload()
        const { data } = await AccountsService.exportCSVAccountAnalytics(payload)
        downloadFileByLink(data.file_path)
        this.$emit('close')
        this.$notification.success({
          message: 'Data export was successful',
          description: '',
        })
      } catch (error) {
        this.$notification.error({
          message: 'Failed to export data',
          description: '',
        })
      } finally {
        this.isExportCSVLoading = false
      }
    },
    prepareExportPayload() {
      const payload = {
        import_type: 'rewards',
        agent_ids: this.selectedAgentIds.length ? [...this.selectedAgentIds] : null,
        project_ids: this.selectedProjectIds.length ? [...this.selectedProjectIds] : null,
        statuses: [this.typeAgents],
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      }

      return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null))
    },
    async fetchArchivedAgents() {
      try {
        const { data } = await AccountsService.getAccounts({ short: true, statuses: 'archived' })
        return data.data
      } catch (error) {
        this.$notification.error({
          message: 'Failed to load archive agents',
          description: '',
        })
      }
    },
    disabledDateHandler(current) {
      if (this.typeAgents !== 'archived') {
        return false
      }
      if (this.minPeriodDate && current < this.minPeriodDate) {
        return true
      }
      if (this.maxPeriodDate && current > this.maxPeriodDate) {
        return true
      }
      return false
    },
  },
  async beforeMount() {
    if (this.typeAgents === 'archived') {
      this.agents = await this.fetchArchivedAgents()
    } else {
      this.agents = this.agentList
    }
  },
}
</script>
