<template>
<div>
  <a-table
    :columns="table.columns"
    :dataSource="table.rows"
    :row-key="record => record.id"
    :pagination="table.pagination"
    :loading="table.loading"
    class="usdt-requisite-table"
    @change="handleTableChange"
  >
    <template v-slot:is_approved="is_approved, record">
      <a-switch :checked="is_approved" :disabled="is_approved || !record.is_active || readonly" @change="approveWallet(record)"/>
    </template>
    <template v-slot:is_active="is_active, record">
      <span v-if="record.is_draft" class="text-gray-5">
          <a-tooltip :title="$t('common.archive')" placement="bottom">
            <a-icon type="cloud" class="font-size-18" />
          </a-tooltip>
        </span>
      <a-switch v-else :checked="is_active" @change="changeStatus(record)" :disabled="readonly"/>
    </template>
    <template v-slot:actions="text, record">
      <div class="flex">
        <span>
          <a-tooltip :title="$t('buttons.btnEdit')" placement="bottom">
            <a-button type="link" icon="edit" @click="showEditWalletModal(record)" :disabled="readonly"/>
          </a-tooltip>
        </span>
        <span>
          <a-popconfirm
            :title="`${$t('buttons.btnDelete')} ${record.address}?`"
            @confirm="deleteWallet(record)"
            :disabled="readonly"
          >
            <a-tooltip :title="$t('buttons.btnDelete')" placement="bottom">
              <a-button icon="delete" type="link" :class="!readonly ? 'text-danger' : 'text-grey'" :disabled="readonly"/>
            </a-tooltip>
          </a-popconfirm>
        </span>
      </div>
    </template>
  </a-table>
</div>
</template>

<script>
import { reactive } from 'vue'
import apiClient from '@/services/axios'

export default {
  props: {
    accountObj: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const table = reactive({
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Approved',
          dataIndex: 'is_approved',
          key: 'is_approved',
          scopedSlots: { customRender: 'is_approved' },
        },
        {
          title: 'Active',
          dataIndex: 'is_active',
          key: 'is_active',
          scopedSlots: { customRender: 'is_active' },
        },
        {
          title: 'Action',
          dataIndex: 'actions',
          align: 'right',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      rows: [],
      pagination: {
        current: 1,
        pageSize: 3,
        total: -1,
      },
      loading: false,
    })

    return {
      table,
    }
  },
  async mounted() {
    await this.fetchPayoutWallets()
  },
  methods: {
    async deleteWallet(record) {
      try {
        this.table.loading = true
        // await apiClient.patch(`admin/accounts/payouts/wallets/${record.id}/draft`)
        await apiClient.delete(`admin/accounts/payouts/wallets/${record.id}`)
        await this.fetchPayoutWallets(true)
      } catch (e) {
        // this.$notification.error({
        //   message: `${this.$t('messages.errorCreatingRequisite')}`,
        //   description: e.message,
        // })
      } finally {
        this.table.loading = false
      }
    },
    async approveWallet(record) {
      try {
        this.table.loading = true
        await apiClient.patch(`admin/accounts/payouts/wallets/${record.id}/approve`)
        await this.fetchPayoutWallets()
      } catch (e) {
        // this.$notification.error({
        //   message: `${this.$t('messages.errorCreatingRequisite')}`,
        //   description: e.message,
        // })
      } finally {
        this.table.loading = false
      }
    },
    async changeStatus(record) {
      try {
        this.table.loading = true
        await apiClient.get(`admin/accounts/payouts/wallets/${record.id}/active/${record.is_active ? 0 : 1}`)
        await this.fetchPayoutWallets()
      } catch (e) {
        // this.$notification.error({
        //   message: `${this.$t('messages.errorCreatingRequisite')}`,
        //   description: e.message,
        // })
      } finally {
        this.table.loading = false
      }
    },
    showEditWalletModal(requisite) {
      this.$emit('openUSDTWalletModal', requisite)
    },
    async handleTableChange(pagination) {
      this.table.pagination.current = pagination.current
      await this.fetchPayoutWallets()
    },
    async fetchPayoutWallets() {
      try {
        this.table.loading = true
        const { current, pageSize } = this.table.pagination
        const payload = {
          page: current,
          results: pageSize,
          agent_id: this.accountObj.id,
          search: '',
        }
        const result = await apiClient.get('/admin/accounts/payouts/wallets', { params: payload })
        this.table.rows = result.data.data
        this.table.pagination.total = result.data.meta.total
        console.log(this.table.pagination)
      } catch (e) {
        // this.$notification.error({
        //   message: `${this.$t('messages.errorCreatingRequisite')}`,
        //   description: e.message,
        // })
      } finally {
        this.table.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.usdt-requisite-table {

}
</style>
