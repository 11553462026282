<template>
  <div>
    <div class="mb-4">
      <div>
        Balance:
        <span class="">
          ${{ balance }}
        </span>
      </div>
      Current limit:
      <span class="font-weight-bold">
        ${{ available }}
      </span>
    </div>
    <a-form-model
      ref="buyLimitForm"
      :model="buyLimitForm"
      :rules="buyLimitFormRules"
    >
      <a-row>
        <a-col :span="24" class="pr-2">
          <a-form-model-item ref="amount" label="Amount" prop="amount">
            <a-input-number
              v-model="buyLimitForm.amount"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="1"
              :max="100000"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="text-center">
        <a-radio-group v-model="buyLimitEditType" button-style="solid">
          <a-radio-button value="buy">
            <a-icon type="arrow-up" /> Buy
          </a-radio-button>
          <a-radio-button value="sell">
            <a-icon type="arrow-down" /> Sell
          </a-radio-button>
          <a-radio-button value="sell_and_write_off">
            <a-icon type="arrow-down" /> <a-icon type="dollar" /> Sell & Write off balance
          </a-radio-button>
        </a-radio-group>
      </div>
    </a-form-model>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          Cancel
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          Confirm
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import AccountsLimitsService from '../../../services/api/accounts/limits/accounts.limits.service'

export default {
  name: 'editBuyLimit',
  props: ['available', 'balance', 'agentId'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,
      buyLimitEditType: 'buy',
      buyLimitForm: {
        amount: 10,
      },
      buyLimitFormRules: {
        amount: [{ required: true, message: 'Please enter sum', trigger: 'blur' }],
      },
    }
  },
  mounted () {
    //
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, type, amount) {
      switch (type) {
        case 'buy': return this.submitBuyLimit(record, amount)
        case 'sell': return this.submitSellLimit(record, amount)
        case 'sell_and_write_off': return this.submitSellAndWriteOffLimit(record, amount)
        default: return false
      }
      // this.$emit('submitModalFunction', record, edit)
    },
    submitBuyLimit(record, amount) {
      this.$emit('submitBuyModalFunction', record, amount)
    },
    submitSellLimit(record, amount) {
      this.$emit('submitSellModalFunction', record, amount)
    },
    submitSellAndWriteOffLimit(record, amount) {
      this.$emit('submitSellAndWriteOffModalFunction', record, amount)
    },
    onSubmit() {
      this.$refs.buyLimitForm.validate(valid => {
        if (valid) {
          this.submitOperation()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async submitOperation() {
      this.submitLoading = true
      return AccountsLimitsService.agentEditBuyLimit(
        this.buyLimitEditType, this.agentId, this.buyLimitForm.amount,
      ).then((response) => {
        this.$message.success(`Succeeded operation: $${response.data.data.amount} to ` + this.buyLimitEditType)
        this.submitModal(response.data.data, this.buyLimitEditType, this.buyLimitForm.amount)
      }).catch(error => {
        this.$message.error('Error creating operation: ' + error.message)
      }).finally(() => {
        this.submitLoading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
