<template>
  <a-form-model
    class="sale-requisite-modal"
    ref="saleRequisiteForm"
    :model="saleRequisiteForm"
    :rules="saleRequisiteRules"
  >
    <a-row class="mb-0">
      <a-col :span="12" class="pr-1">
        <a-form-model-item ref="name" :label="`${$t('common.sender')} ${$t('common.name').toLowerCase()}`" prop="name">
          <a-input v-model="saleRequisiteForm.name" :placeholder="`${$t('pre.eg')}Ivan Ivanov`"/>
        </a-form-model-item>
      </a-col>
      <a-col :span="12" class="pr-1">
        <a-form-model-item ref="amount" :label="`${$t('common.amount')}`" prop="amount">
          <a-input v-model="saleRequisiteForm.amount" type="number" placeholder="1000"/>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row class="mb-0">
      <a-col :span="24" class="pr-1">
        <div class="label-header">
          <div class="ant-col ant-form-item-label">
            <label title="Sender name" class="ant-form-item-required">{{ $t('common.paySystems')
              }}</label>
          </div>
          <a @click="toogleSelectAllPsystems">
            <template v-if="allPSystemsAreSelected">Выбрать все</template>
            <template v-else>Убрать все</template>
          </a>
        </div>

        <template v-if="saleRequisiteForm.psystems.length > 0">
        <div class="psystems-list">
          <template v-for="psystem in saleRequisiteForm.psystems">
            <div class="psystem" :key="psystem.id">
              <a-checkbox @click="errorPsystemsLength = false" v-model="psystem.selected">{{
                  psystem.name
                }}
              </a-checkbox>
            </div>
          </template>
        </div>
        <div class="ant-form-explain mt-2" style="color: #f5222e;" v-if="errorPsystemsLength">{{ $t('validations.psystemIsRequired') }}</div>
        </template>

        <div v-else class="text-center">
          <a-icon type="loading" class="text-primary font-size-60" />
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="text-right">
        <div class="text-right mt-3">
          <a-button type="primary" @click="onSubmit" :class="{'disabled': submitLoading}" :loading="submitLoading">
            {{ $t('buttons.btnSave') }}
          </a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import AccountsRequisitesService from '../../../services/api/accounts/requisites/accounts.requisites.service'

export default {
  async mounted() {
    await this.fetchPsystems()
    if (this.requisite) {
      this.saleRequisiteForm.id = this.requisite.id
      this.saleRequisiteForm.name = this.requisite.name
      this.saleRequisiteForm.amount = this.requisite.max_amount

      const selectedPsystems = this.requisite.psystems.map(el => el.id)
      this.saleRequisiteForm.psystems.map(el => {
        if (selectedPsystems.includes(el.id)) {
          el.selected = true
        }
      })

      if (selectedPsystems.length === this.saleRequisiteForm.psystems.length) {
        this.allPSystemsAreSelected = false
      } else {
        this.allPSystemsAreSelected = true
      }
    }
  },
  props: ['accountObj', 'requisite'],
  data() {
    const saleRequisiteForm = {
      id: null,
      name: '',
      amount: 1000,
      psystems: [],
    }

    return {
      saleRequisiteForm,
      submitLoading: false,
      errorPsystemsLength: false,
      saleRequisiteRules: {
        name: [{ required: true, message: `${this.$t('validations.senderNameRequired')}`, trigger: 'change' }],
        amount: [{ required: true, message: `${this.$t('validations.amountRequired')}`, trigger: 'change' }],
      },
      allPSystemsAreSelected: true,
    }
  },
  methods: {
    async onSubmit() {
      this.$refs.saleRequisiteForm.validate(async valid => {
        if (valid) {
          if (this.saleRequisiteForm.psystems.find(el => el.selected) === undefined) {
            this.errorPsystemsLength = true
            return false
          }
          if (this.saleRequisiteForm.id === null) {
            await this.createRequisite()
          } else {
            await this.updateRequisite()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async createRequisite() {
      try {
        this.submitLoading = true
        const psystemIds = []
        this.saleRequisiteForm.psystems.map(el => {
          if (el.selected) {
            psystemIds.push(el.id)
          }
        })
        const payload = {
          name: this.saleRequisiteForm.name,
          comment: '',
          max_amount: this.saleRequisiteForm.amount,
          psystem_ids: psystemIds,
          account_id: this.accountObj.id,
        }
        await AccountsRequisitesService.createRequisiteB(payload)
        this.$notification.success({
          message: `${this.$t('messages.requisiteCreated')}`,
        })
      } catch (e) {
        this.$notification.error({
          message: `${this.$t('messages.errorCreatingRequisite')}`,
          description: e.message,
        })
      } finally {
        this.$emit('closeModalFunction')
        this.submitLoading = false
      }
    },
    async updateRequisite() {
      try {
        this.submitLoading = true
        const psystemIds = []
        this.saleRequisiteForm.psystems.map(el => {
          if (el.selected) {
            psystemIds.push(el.id)
          }
        })
        const payload = {
          id: this.saleRequisiteForm.id,
          name: this.saleRequisiteForm.name,
          comment: '',
          max_amount: this.saleRequisiteForm.amount,
          psystem_ids: psystemIds,
          account_id: this.accountObj.id,
        }
        await AccountsRequisitesService.updateRequisiteB(payload)
        this.$notification.success({
          message: `${this.$t('messages.requisiteCreated')}`,
        })
      } catch (e) {
        this.$notification.error({
          message: `${this.$t('messages.errorCreatingRequisite')}`,
          description: e.message,
        })
      } finally {
        this.$emit('closeModalFunction')
        this.submitLoading = false
      }
    },
    closeModal() {},
    toogleSelectAllPsystems() {
      if (!this.allPSystemsAreSelected) {
        // eslint-disable-next-line no-return-assign
        this.saleRequisiteForm.psystems.map(el => el.selected = false)
        this.allPSystemsAreSelected = true
      } else {
        // eslint-disable-next-line no-return-assign
        this.saleRequisiteForm.psystems.map(el => el.selected = true)
        this.allPSystemsAreSelected = false
      }
    },
    async fetchPsystems() {
      const response = await AccountsRequisitesService.fetchAllPsystemsForRequisitesB()
      response.map(el => {
        this.saleRequisiteForm.psystems.push({ ...el, selected: false })
      })
    },
  },
}
</script>

<style lang="scss">
.sale-requisite-modal {
  .label-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: 14px;
      margin-bottom: 7.5px;
    }
  }
  .psystems-list {
    display: flex;
    flex-wrap: wrap;
    .psystem {
      flex: 0 1 25%;
    }
  }
}
</style>
