import apiClient from '../../../axios'

export default class AccountsInterconnectsService {
  static async getAgentInterconnects(agentId) {
    try {
      const response = await apiClient.get(`/admin/accounts/${agentId}/interconnects`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching agent interconnects:', error)
      throw error
    }
  }

  static async createAgentInterconnect(agentId, interconnectData) {
    try {
      const response = await apiClient.post(`/admin/accounts/${agentId}/interconnects/`, interconnectData)
      return response.data.data
    } catch (error) {
      console.error('Error creating agent interconnect:', error)
      throw error
    }
  }

  static async updateAgentInterconnect(agentId, interconnectId, interconnectData) {
    try {
      const response = await apiClient.patch(`/admin/accounts/${agentId}/interconnects/${interconnectId}`, interconnectData)
      return response.data.data
    } catch (error) {
      console.error('Error updating agent interconnect:', error)
      throw error
    }
  }

  static async deleteAgentInterconnect(agentId, interconnectId) {
    try {
      const response = await apiClient.delete(`/admin/accounts/${agentId}/interconnects/${interconnectId}`)
      return response.data.data
    } catch (error) {
      console.error('Error deleting agent interconnect:', error)
      throw error
    }
  }
}
