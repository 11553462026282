import apiClient from '../../../axios'

export default class AccountsBalanceOperationsService {
  static async getAccountBalanceOperations(params) {
    try {
      const response = await apiClient.get('/admin/accounts/balance/operations', { params })
      return response
    } catch (error) {
      console.error('Error fetching account balance operations:', error)
      throw error
    }
  }

  static async getAccountBalanceOperationsDigits(params) {
    try {
      const response = await apiClient.get('/admin/accounts/balance/operations/analytics/digits', { params })
      return response
    } catch (error) {
      console.error('Error fetching account balance operations digits:', error)
      throw error
    }
  }

  static async getAccountBalanceFlowDigits(params) {
    try {
      const response = await apiClient.get('/admin/accounts/balance/operations/analytics/flows', { params })
      return response.data.data
    } catch (error) {
      console.error('Error fetching account balance flow digits:', error)
      throw error
    }
  }

  static async getAccountsDebtAndBalanceDigits(params) {
    try {
      const response = await apiClient.get('/admin/accounts/analytics/balance_debt', { params })
      return response.data.data
    } catch (error) {
      console.error('Error fetching accounts debt and balance digits:', error)
      throw error
    }
  }
}
