import apiClient from '../../../axios'

export default class AccountsNotesServices {
  static async fetchNoteEditorDetails(agentId) {
    try {
      const response = await apiClient.get(`/admin/accounts/${agentId}/notes`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching note editor details:', error)
      throw error
    }
  }
}
