import apiClient from '../../../axios'

export default class AccountsLimitsService {
  static async agentIncreaseBuyLimit(agentId, amount) {
    try {
      const response = await apiClient.patch(`/admin/accounts/${agentId}/buy_limit/buy`, { amount })
      return response
    } catch (error) {
      console.error('Error increasing buy limit:', error)
      throw error
    }
  }

  static async agentDecreaseBuyLimit(agentId, amount) {
    try {
      const response = await apiClient.patch(`/admin/accounts/${agentId}/buy_limit/sell`, { amount })
      return response
    } catch (error) {
      console.error('Error decreasing buy limit:', error)
      throw error
    }
  }

  static async agentDecreaseBuyLimitAndWriteOff(agentId, amount) {
    try {
      const response = await apiClient.patch(`/admin/accounts/${agentId}/buy_limit/sell_write_off`, { amount })
      return response
    } catch (error) {
      console.error('Error decreasing and writing off buy limit:', error)
      throw error
    }
  }

  static async agentEditBuyLimit(editType, agentId, amount) {
    try {
      switch (editType) {
        case 'buy':
          return await AccountsLimitsService.agentIncreaseBuyLimit(agentId, amount)
        case 'sell':
          return await AccountsLimitsService.agentDecreaseBuyLimit(agentId, amount)
        case 'sell_and_write_off':
          return await AccountsLimitsService.agentDecreaseBuyLimitAndWriteOff(agentId, amount)
        default:
          console.error('Invalid edit type:', editType)
          return false
      }
    } catch (error) {
      console.error('Error editing buy limit:', error)
      throw error
    }
  }
}
