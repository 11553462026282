import apiClient from '../../../axios'

export default class AccountsOperationsService {
  static async storeAgentAdminOperation(agentId, data) {
    try {
      const response = await apiClient.post(`/admin/accounts/${agentId}/operations`, data)
      return response.data.data
    } catch (error) {
      console.error('Error storing agent admin operation:', error)
      throw error
    }
  }

  static async getAgentAdminOperations(agentId, month, reqData) {
    try {
      const response = await apiClient.get(`/admin/accounts/${agentId}/operations`, {
        params: { ...reqData, month },
      })
      return response.data
    } catch (error) {
      console.error('Error fetching agent admin operations:', error)
      throw error
    }
  }
}
