<template>
  <div>
    <div class="d-flex">
      <div></div>
      <div class="ml-auto">
        <a-button icon="plus" size="small" @click="newInterconnectModal" :disabled="readonly">Connect</a-button>
      </div>
    </div>
    <div class="mt-1">
      <a-table
        :columns="columns"
        :data-source="data"
        :row-key="record => record.id"
        :loading="loading"
        :scroll="{ y: 140 }"
        :bordered="false"
        :pagination="false"
        size="small"
        class="ant-small-table"
      >
        <template v-slot:connected="connected">
          <agent-avatar :agent="connected"/>
          {{ connected.name }}
        </template>
        <template v-slot:options="options">
          <div>
            <a-icon type="notification" :class="(options.notifications.enabled === true) ? 'text-primary' : 'text-gray-5'" />
            >>
            <span>
              Buy:
              <span>
                <span class="pr-1">
                  <a-icon type="credit-card" :class="(options.notifications.buy_orders.new === true) ? 'text-primary' : 'text-gray-5'" />
                </span>
                <span class="pr-1">
                  <a-icon type="message" :class="(options.notifications.buy_orders.chat_messages === true) ? 'text-primary' : 'text-gray-5'" />
                </span>
              </span>
            </span>
            <span>
              Sell:
              <span>
                <span class="pr-1">
                  <a-icon type="credit-card" :class="(options.notifications.sell_orders.new === true) ? 'text-primary' : 'text-gray-5'" />
                </span>
                <span class="pr-1">
                  <a-icon type="message" :class="(options.notifications.sell_orders.chat_messages === true) ? 'text-primary' : 'text-gray-5'" />
                </span>
              </span>
            </span>
          </div>
        </template>
        <template v-slot:operation="text, record">
          <span class="font-size-16">
            <a-button type="link" icon="edit" :disabled="readonly" @click="editInterconnectModal(record)" />
          </span>
        </template>
      </a-table>
      <a-modal
        v-model="interconnectModalOpened"
        :destroyOnClose="true"
        :title="activeInterconnect ? `Edit interconnect ${activeInterconnect.id}` : 'Add interconnect'"
        :footer="null"
        :afterClose="closeInterconnectModal"
        class="interconnect-modal"
        width="480px"
        height="240px"
      >
        <interconnect-modal
          :interconnect="activeInterconnect"
          :agent-id="agentId"
          @closeModalFunction="closeInterconnectModal"
          @submitModalFunction="interconnectSubmitted"
          @deleteModalFunction="interconnectDeleted"
        />
      </a-modal>
    </div>
  </div>
</template>

<script>
import InterconnectModal from '@/views/accounts/interconnects/interconnectModal.vue'
import agentAvatar from '@/components/agent/AgentAvatar.vue'
import AccountsInterconnectsService from '../../../services/api/accounts/interconnects/accounts.interconnects.service'

const columns = [
  {
    title: 'Connected To',
    dataIndex: 'connected',
    key: 'connected_id',
    width: '20%',
    scopedSlots: { customRender: 'connected' },
  },
  {
    title: 'Options',
    dataIndex: 'options',
    key: 'options',
    width: '30%',
    align: 'left',
    scopedSlots: { customRender: 'options' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: '10%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'agentInterconnectList',
  props: {
    agentId: {
      type: [String, Number],
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    agentAvatar,
    InterconnectModal,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      activeInterconnect: { id: 0 },
      interconnectModalOpened: false,
    }
  },
  mounted() {
    this.getInterconnects()
  },
  methods: {
    async getInterconnects() {
      this.loading = true
      return AccountsInterconnectsService.getAgentInterconnects(this.agentId).then((response) => {
        this.data = response
      }).catch(error => {
        console.log(error)
        this.$message.error('Error while loading agent interconnects')
      }).finally(() => {
        this.loading = false
      })
    },
    editInterconnectModal(interconnect) {
      this.activeInterconnect = interconnect
      this.interconnectModalOpened = true
    },
    newInterconnectModal() {
      this.activeInterconnect = undefined
      this.interconnectModalOpened = true
    },
    closeInterconnectModal() {
      // alert('some close')
      this.interconnectModalOpened = false
    },
    interconnectSubmitted(record, edit) {
      const idx = this.data.findIndex(obj => obj.id === record.id)
      if (!edit) {
        if (idx > -1) {
          this.data[idx] = record
        } else {
          this.data.unshift(record)
        }
      } else {
        this.data[idx] = record
      }
      this.interconnectModalOpened = false
    },
    interconnectDeleted(recordId) {
      const idx = this.data.findIndex(obj => obj.id === recordId)
      this.data.splice(idx, 1)
      this.interconnectModalOpened = false
    },
  },
}
</script>

<style scoped>

</style>
